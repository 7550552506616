import {
  cubicBezier,
  motion,
  type Transition,
  useAnimationFrame,
  useMotionValue,
  useScroll,
  useSpring,
  useTransform,
  useVelocity,
  type Variants,
  wrap,
} from "framer-motion";
import { useRef } from "preact/hooks";
import type { JSX } from "preact/jsx-runtime";

export interface AnimatedTextProps
  extends JSX.HTMLAttributes<HTMLHeadingElement> {
  text: string;
  delay?: number;
}

const AnimatedText = ({
  text,
  delay,
  ...props
}: AnimatedTextProps) => {
  const letters = text.split("");

  const containerVariant: Variants = {
    visible: {
      transition: {
        staggerChildren: 0.04,
        delayChildren: delay || 0,
      } as Transition,
    },
  };

  const letterVariant = {
    hidden: {
      y: "100%",
    },
    visible: {
      y: 0,
      transition: {
        ease: cubicBezier(0, 1, 0.4, 1),
        duration: 1.25,
      },
    },
  };

  return (
    <motion.h1
      {...props}
      variants={containerVariant}
      initial="hidden"
      animate="visible"
      style={{ overflow: "hidden" }}
    >
      {letters.map((char, index) => (
        <motion.span
          key={index}
          variants={letterVariant}
          style={{ display: "inline-block" }}
        >
          {char === " " ? "\u00A0" : char}
        </motion.span>
      ))}
    </motion.h1>
  );
};

export function Hero() {
  const heroRef = useRef<HTMLElement>(null);
  const { scrollY, scrollYProgress } = useScroll();

  const scaleX = useSpring(scrollYProgress, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
  });

  const scale = useTransform(scaleX, [0, 0.5], [1, 0]);
  const opacity = useTransform(scaleX, [0, 0.5], [1, 0]);

  	const BASE_VELOCITY = 2;

	const baseX = useMotionValue(0);
	const scrollVelocity = useVelocity(scrollY);
	const smoothVelocity = useSpring(scrollVelocity, {
		damping: 50,
		stiffness: 400
	});
	const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 20], {
		clamp: false
	});

	const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

	const directionFactor = useRef<number>(1);
	useAnimationFrame((_t, delta) => {
		let moveBy = directionFactor.current * BASE_VELOCITY * (delta / 1000);

		if (velocityFactor.get() < 0) {
			directionFactor.current = -1;
		} else if (velocityFactor.get() > 0) {
			directionFactor.current = 1;
		}

		moveBy += directionFactor.current * moveBy * velocityFactor.get();
		baseX.set(baseX.get() + moveBy);
	});

  return (
    <>
      <section
        className="h-[80vh] w-full sticky top-0 overflow-hidden"
        ref={heroRef}
      >
        <motion.div
          className="h-[80vh] flex flex-col justify-center items-center p-32 space-y-4 text-white font-black tracking-widest row-span-full col-span-full z-10 opacity-100 scale-100"
          style={{
            opacity
          }}
        >
          <motion.div className="text-4xl lg:text-5xl 2xl:text-6xl">
            <AnimatedText text="FIGHTING FOR A BETTER" />
          </motion.div>
          <motion.div className="flex text-7xl lg:text-8xl 2xl:text-9xl" style={{ x, whiteSpace: "nowrap" }} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
			 	<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
				<span>FUTURE.&nbsp;</span>
				<span>SOCIETY.&nbsp;</span>
				<span>LIFE.&nbsp;</span>
          </motion.div>
        </motion.div>
      </section>
    </>
  );
}
